
      import API from "!../node_modules/.pnpm/@umijs+bundler-webpack@4.3.20_type-fest@1.4.0_typescript@5.6.2_webpack@5.94.0/node_modules/@umijs/bundler-webpack/compiled/style-loader/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../node_modules/.pnpm/@umijs+bundler-webpack@4.3.20_type-fest@1.4.0_typescript@5.6.2_webpack@5.94.0/node_modules/@umijs/bundler-webpack/compiled/style-loader/runtime/styleDomAPI.js";
      import insertFn from "!../node_modules/.pnpm/@umijs+bundler-webpack@4.3.20_type-fest@1.4.0_typescript@5.6.2_webpack@5.94.0/node_modules/@umijs/bundler-webpack/compiled/style-loader/runtime/insertBySelector.js";
      import setAttributes from "!../node_modules/.pnpm/@umijs+bundler-webpack@4.3.20_type-fest@1.4.0_typescript@5.6.2_webpack@5.94.0/node_modules/@umijs/bundler-webpack/compiled/style-loader/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../node_modules/.pnpm/@umijs+bundler-webpack@4.3.20_type-fest@1.4.0_typescript@5.6.2_webpack@5.94.0/node_modules/@umijs/bundler-webpack/compiled/style-loader/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../node_modules/.pnpm/@umijs+bundler-webpack@4.3.20_type-fest@1.4.0_typescript@5.6.2_webpack@5.94.0/node_modules/@umijs/bundler-webpack/compiled/style-loader/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../node_modules/.pnpm/css-loader@6.7.1_webpack@5.94.0/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].oneOf[1].use[1]!../node_modules/.pnpm/@umijs+bundler-webpack@4.3.20_type-fest@1.4.0_typescript@5.6.2_webpack@5.94.0/node_modules/@umijs/bundler-webpack/compiled/postcss-loader/index.js??ruleSet[1].rules[6].oneOf[1].use[2]!../node_modules/.pnpm/@umijs+bundler-webpack@4.3.20_type-fest@1.4.0_typescript@5.6.2_webpack@5.94.0/node_modules/@umijs/bundler-webpack/compiled/sass-loader/index.js!./global.scss";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../node_modules/.pnpm/css-loader@6.7.1_webpack@5.94.0/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].oneOf[1].use[1]!../node_modules/.pnpm/@umijs+bundler-webpack@4.3.20_type-fest@1.4.0_typescript@5.6.2_webpack@5.94.0/node_modules/@umijs/bundler-webpack/compiled/postcss-loader/index.js??ruleSet[1].rules[6].oneOf[1].use[2]!../node_modules/.pnpm/@umijs+bundler-webpack@4.3.20_type-fest@1.4.0_typescript@5.6.2_webpack@5.94.0/node_modules/@umijs/bundler-webpack/compiled/sass-loader/index.js!./global.scss";
       export default content && content.locals ? content.locals : undefined;
