import { PropServiceClient } from '@/grpc/client/bpt/hall/prop/v1/prop.client';
import { GrpcWebFetchTransport } from '@mtt/grpc';

import {
  BatchGetPropReq,
  GetPropReq,
  GetUserCoinReq,
} from '@/grpc/client/bpt/hall/prop/v1/prop';
import { getApiPrefix } from '@/utils/api-prefix';

const createNoAuthTransport = () => {
  return new GrpcWebFetchTransport({
    format: 'binary',
    baseUrl: `${getApiPrefix()}/bpt_hall_prop_noauth/`,
  });
};
class HallPropMgr {
  private noAuthClient: PropServiceClient;

  constructor() {
    this.noAuthClient = new PropServiceClient(createNoAuthTransport());
  }
  async batchGetProp(ids: Array<number>) {
    return await this.noAuthClient.batchGetProp(BatchGetPropReq.create({ ids }))
      .response;
  }

  async getProp(id: number) {
    return await this.noAuthClient.getProp(GetPropReq.create({ id })).response;
  }

  async getUserCoin(uid: number) {
    return await this.noAuthClient.getUserCoin(GetUserCoinReq.create({ uid }))
      .response;
  }
}

export const hallPropMgr = new HallPropMgr();
